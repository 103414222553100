import { Component, OnInit } from '@angular/core';

import { CommService } from "../comm.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-potvrzeni-zmetku',
  templateUrl: './potvrzeniZmetku.component.html',
  styleUrls: ['../app.component.css']
})
export class PotvrzeniZmetkuComponent implements OnInit{
seconds=5;
zaznam={"name":""};
interval = null;
  constructor(private comm: CommService,

    private router:Router){}

    ngOnInit() {
      this.zaznam.name=this.comm.zaznam.name;
      this.interval = setInterval(() => {
        this.odecet();
        console.log("jede");
      }, 1000);
      this.comm.zaznamSmeny;
    }

    getAuthUser() {
      return this.comm.getAuthUser()
    }


    odecet(){
      if(this.seconds >=0) {
        this.seconds--;
        this.potvrzeni();
        console.log("Odečet");
        console.log(this.seconds);
      } else {
                clearInterval(this.interval);
              }
    }

    potvrzeni(){
      if(this.seconds===0){
        console.log(this.comm.zaznam);
        this.comm.postZaznamZavady(this.comm.zaznam).subscribe(
          (response) => console.log(response),
          (error) => console.log(error));
        this.router.navigate(['/zavady'])

        //return true;
      }
    }

    zpet(){
      clearInterval(this.interval);
      this.router.navigate(['/zavady'])
    }
      //this.freq=this.vybraneZavady[vybraneid].freq+1;
      //console.log("FREQ:"+this.freq);
      //this.comm.postFrekvenceZavady(this.freq).subscribe(
      //(response) => console.log(response),
      //(error) => console.log(error));
    }
