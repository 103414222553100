import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-zmetkovitostv',
  templateUrl: './zmetkovitostvyroba.component.html',
  styleUrls: ['../../app.component.css']
})
export class ZmetkovitostVComponent implements OnInit{
 zavadyv=[];
 itemclass;
 itemclass2;
 seconds=5;
 interval = null;
 idSmena={"smenaId":"", "type":""};
 stav;
constructor(private comm: CommService, private router:Router){}

ngOnInit() {
 this.itemclass = "none";
 this.itemclass2 = "none";
 this.zavadyv=this.comm.zavadyv;
}

getAuthUser() {
 return this.comm.getAuthUser()
}

open(){
 this.seconds=5;
 this.itemclass = "block";
 this.interval = setInterval(() => {
   this.odecet();
   console.log("jede");
 }, 1000);
}

close(){
  this.itemclass = "none";
  this.itemclass2 = "none";
   clearInterval(this.interval);
}

odecet(){
     if(this.seconds >=0) {
       this.seconds--;
       this.potvrzeni();
       console.log("Odečet");
       console.log(this.seconds);
     } else {
               clearInterval(this.interval);
               this.close();
             }
   }

potvrzeni(){
     if(this.seconds===0){
       console.log(this.comm.zaznam);
       this.comm.postZaznamZavady(this.comm.zaznam).subscribe(
         (response) => {
           console.log(response);
           this.idSmena.smenaId=this.comm.zaznamSmeny.smenaId;
           this.comm.getStatusSmena(this.idSmena).subscribe(
             (response) => {
               this.stav=response.json();
               this.comm.zavady=this.stav[0].count;
               console.log(this.comm.zavady); console.log(this.comm.ps_pn.stoplineSmena);
               if (this.comm.zavady>=this.comm.ps_pn.stoplineSmena){console.log("!!!"); this.itemclass2 = "block";}
             },
             (error) => console.log(error));
             return this.stav;},
         (error) => console.log(error));
         this.close();
     //return true;
     }
   }  

vybrano(vybraneid){
   this.comm.zaznam.zavadaID="";
   this.comm.zaznam.zavadaID=this.comm.zavadyv[vybraneid].id;
   this.comm.zaznam.name=this.comm.zavadyv[vybraneid].name;
   this.comm.zaznam.pn=this.comm.ps_pn.pn;
   this.comm.zaznam.osoba=this.comm.zaznamSmeny.osoba;
   this.comm.zaznam.ip=this.comm.ipAddress;
   this.comm.zaznam.batch=this.comm.zaznamSmeny.batch;
   this.comm.zaznam.idSmena=this.comm.zaznamSmeny.smenaId;
   console.log("Vybráno:"+this.comm.zaznam.batch+this.comm.zaznam.osoba);
   console.log("Vybráno:"+this.comm.zaznam.zavadaID+this.comm.zaznam.name+this.comm.zaznam.pn);
 //this.router.navigate(['/potvrzeni-zmetku'])
 this.open();
}

}
