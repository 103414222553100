import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { NotifierService } from 'angular-notifier'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-zavady',
  templateUrl: './zavady.component.html',
  styleUrls: ['../app.component.css']
})
export class ZavadyComponent implements OnInit{
  event;
  itemclass;
  itemclass2;
  itemclass3;
  itemclass4;
  seconds=5;
  datumsetValue;
  datumTextValue;
  interval = null;
  today;
  date;
  time;
  todaytime;
  mytime=0;
  zmetkovitostSmena={"smenaId":"", "osoba":"", "batch":"", "pn":"", "total":null, "totalNOK":null, "totalOK":null, "NokOk":null, "ip":"", "status":null};
  totalNOK="";
  pocetZavad;
  status = 'ONLINE';
  zaznamSmeny={"pn":"", "batch":""};
  isConnected = true;
  celkemks="";
  prubeh=[];
  stav;
  summ:number;
  idSmena={"idSmena":"", "type":""};
  zaznamPoruchy={"smenaId":"", "status":null};

  zavadyv=[];
  zavadyk=[];
  zavadyt=[];
  check_smena=[];
  smena={smenaId:""};

  kartaA;
  kartaB;

  kartaB_data={"smena_id":"", "osoba":null, "identifier2":"",
  "note":"", "check_visual":null, "check_place":null, "check_training":null,
  "ip":"", "date":"", "time":"", "datum":"",
  "operator_visual":null, "operator_place":null, "operator_training":null, 
  "operator":null, "operator_note":null, "funkcni_zkouska":null, "testovaci_sada":null, "kontrola1":null, "kontrola2":null};

  hodina;
  minuta;

  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, private datePipe: DatePipe, notifier: NotifierService){  this.notifier = notifier;}


  ngOnInit() {
    this.itemclass = "none";
    this.itemclass2 = "none";
    this.itemclass3 = "none";
    this.itemclass4 = "none";
    this.datumsetValue ="ne";
    this.zaznamSmeny.pn=this.comm.zaznamSmeny.pn;
    this.zaznamSmeny.batch=this.comm.zaznamSmeny.batch;
    this.zmetkovitostSmena.smenaId="";
    this.zmetkovitostSmena.osoba="";
    this.zmetkovitostSmena.batch="";
    this.zmetkovitostSmena.pn="";
    this.zmetkovitostSmena.total=null;
    this.zmetkovitostSmena.totalNOK=null;
    this.zmetkovitostSmena.totalOK=null;
    this.zmetkovitostSmena.NokOk=null;
    console.log("-> 2 <-");
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.zavadyv=this.comm.zavadyv;
    this.zavadyk=this.comm.zavadyk;
    this.zavadyt=this.comm.zavadyt;
    console.log(this.comm.ps_pn);

    this.date = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    this.time = this.datePipe.transform(Date.now(), 'HH:mm');
    this.todaytime = Date.now();
  }

 

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  openTab(evt, polozkaTab) {
    var i, tabcontent;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    document.getElementById(polozkaTab).style.display = "block";
  }

  open(){
    this.itemclass = "block";
  }
  
  close(){
     this.itemclass = "none";
     this.itemclass3 = "none";
     this.itemclass4 = "none";
  }

  addToForm(value) {
      this.celkemks = this.celkemks.toString() + value.toString()
  }

  resetForm() {
      this.celkemks = ''
  }

  loading(){
    this.itemclass = "none";
    this.itemclass2 = "block";
    this.seconds=5;
    this.interval = setInterval(() => {
      if(this.seconds >=0) {
        this.seconds--;
        if(this.seconds===0){
          this.comm.logout();
        }
      } else {
                clearInterval(this.interval);
              }
    }, 1000);
  }

  onSubmit(){
    var c=this.celkemks;
    console.log(c);
    if (c == "")
    {
      this.notifier.notify('warning', 'Zadejte celkový počet ks')
    }
    else{
      this.notifier.notify('success', 'Zapisování směny')
      this.loading();
      this.odeslani();
    }
   
  }

  odeslani(){
    this.comm.zs_zaznamsmeny.osoba=this.comm.zaznamSmeny.osoba;
    this.comm.zs_zaznamsmeny.batch=this.comm.zaznamSmeny.batch;
    this.comm.zs_zaznamsmeny.pripravek=this.comm.zaznamSmeny.pripravek;
    this.comm.zs_zaznamsmeny.pn=this.comm.ps_pn.pn;
    this.comm.zs_zaznamsmeny.normohodina=this.comm.ps_pn.normohodina;
    this.comm.zs_zaznamsmeny.smenaId=this.comm.zaznamSmeny.smenaId;
    this.comm.zs_zaznamsmeny.ip=this.comm.ipAddress;
    console.log(this.comm.zaznamSmeny.smenaId);
    this.comm.getStatusSmena(this.comm.zaznamSmeny).subscribe(
      (response) => {
        this.stav=response.json();
        console.log(this.stav);
        console.log(this.comm.zavady);
        this.comm.zavady=this.stav[0].count;

                this.comm.zs_zaznamsmeny.totalNOK=this.comm.zavady;
                console.log("->");
                console.log(this.comm.zs_zaznamsmeny.totalNOK);
                console.log("<-");

                this.comm.zs_zaznamsmeny.totalOK=parseInt(this.celkemks);
                this.comm.zs_zaznamsmeny.total=parseInt(this.comm.zs_zaznamsmeny.totalOK) + this.comm.zs_zaznamsmeny.totalNOK;
                this.comm.zs_zaznamsmeny.NokOk=(this.comm.zs_zaznamsmeny.totalNOK / this.comm.zs_zaznamsmeny.total)*100;

                console.log(this.comm.zs_zaznamsmeny);
                
                this.comm.postUpdateZaznamSmena(this.comm.zs_zaznamsmeny).subscribe(
                   (response) => {console.log(response); this.logout()},
                  (error) => console.log(error));
                  return true;

      },
      (error) => console.log(error));
      return this.stav;
    
  }

  logout() {
    return this.comm.logout()
  }

  getPrubeh(){
    delete this.stav;
    delete this.prubeh;
    this.comm.getStatusSmena(this.comm.zaznamSmeny).subscribe(
      (response) => {
        this.stav=response.json();
        this.comm.zavady=this.stav[0].count;
        this.comm.getHistorySmena(this.comm.zaznamSmeny).subscribe(
          (response) => {
            this.prubeh=response.json();
            console.log(response);
            this.openTab(event, 'zmetkovitoststat');
          },
          (error) => console.log(error));
          return this.prubeh;
      },
      (error) => console.log(error));
      return this.stav;
  }

fehler(){
  this.itemclass3 = "block";
}

onSubmitFehler(){
  this.comm.zaznamPoruchy.status=1;
  this.comm.zaznamPoruchy.smenaId=this.comm.zaznamSmeny.smenaId;
  this.comm.zaznamPoruchy.osoba=this.comm.zaznamSmeny.osoba;
  console.log(this.comm.zaznamPoruchy);
  this.comm.postZaznamPoruchy(this.comm.zaznamPoruchy).subscribe(
    (response) => {
    console.log(response);
    this.itemclass3 = "none";
    this.notifier.notify('success', 'Upozornění bylo předáno seřizovači')
     },
    (error) => console.log(error));
}

postKartaB(form:NgForm){
  console.log('form.value', form.value);
  if (isNaN(form.value.osoba))
    {this.notifier.notify('warning', 'Osobní číslo kontrolora nesmí obsahovat text')} 
    else {
      if (form.value.operator_note!=''){this.kartaB_data.operator_note=form.value.operator_note} else {this.kartaB_data.operator_note="-"};
      if (form.value.note!=''){this.kartaB_data.note=form.value.note} else {this.kartaB_data.note="-"};

      if (form.value['check_visual']==true){this.kartaB_data.check_visual=1} else{this.kartaB_data.check_visual=0};
      if (form.value['check_place']==true){this.kartaB_data.check_place=1} else{this.kartaB_data.check_place=0};
      if (form.value['check_training']==true){this.kartaB_data.check_training=1} else{this.kartaB_data.check_training=0};

      if (form.value['operator_visual']==true){this.kartaB_data.operator_visual=1} else{this.kartaB_data.operator_visual=0};
      if (form.value['operator_place']==true){this.kartaB_data.operator_place=1} else{this.kartaB_data.operator_place=0};
      if (form.value['operator_training']==true){this.kartaB_data.operator_training=1} else{this.kartaB_data.operator_training=0};

      if (form.value['funkcni_zkouska']==true){this.kartaB_data.funkcni_zkouska=1} else{this.kartaB_data.funkcni_zkouska=0};
      if (form.value['testovaci_sada']==true){this.kartaB_data.testovaci_sada=1} else{this.kartaB_data.testovaci_sada=0};
      if (form.value['kontrola1']==true){this.kartaB_data.kontrola1=1} else{this.kartaB_data.kontrola1=0};
      if (form.value['kontrola2']==true){this.kartaB_data.kontrola2=1} else{this.kartaB_data.kontrola2=0};

      if (form.value.osoba!=''){this.kartaB_data.osoba=form.value.osoba};
      
      if (form.value.date!=''){this.kartaB_data.date=form.value.date};
      if (form.value.time!=''){this.kartaB_data.time=form.value.time};
      
      if (this.mytime == 0){
        this.kartaB_data.datum = this.datePipe.transform(Date.now(), 'yyyy-MM-dd')+" "+this.datePipe.transform(Date.now(), 'HH:mm')+":00";
      } else {this.kartaB_data.datum=this.kartaB_data.date+" "+form.value.hodina+":"+form.value.minuta+":00";};
      
      this.kartaB_data.operator=this.comm.getAuthUser().cislo;
      this.kartaB_data.smena_id=this.comm.zaznamSmeny.smenaId;
      this.kartaB_data.identifier2="ZBO";
      console.log(this.kartaB_data);
      this.comm.postKartaB(this.kartaB_data).subscribe(
        (response) => {
          console.log(response);
          console.log("data:");
          console.log(this.kartaB_data);
          this.notifier.notify('success', 'Data odeslána');
          this.itemclass4 = "none";
          form.reset();
          this.mytime == 0;
          document.getElementById("currentTime").style.display = "block";
          document.getElementById("myTime").style.display = "none";
        },
        (error) => {console.log(error); this.notifier.notify('danger', 'Chyba, data nebyla odeslána')
      });
    }
}

karta_B(){
  this.mytime = 0;
  this.todaytime = Date.now();
  console.log(this.todaytime);
  console.log(this.comm.zaznamSmeny.smenaId);
  this.smena.smenaId=this.comm.zaznamSmeny.smenaId;
  this.comm.postkartaB_check(this.smena).subscribe(
    (response) => {
      this.check_smena=response.json();
      console.log(this.check_smena);
      this.kartaB_data.note="-";
      this.kartaB_data.operator_note="-";
    //if (this.check_smena[0].smena_id>0){
      this.itemclass4 = "block";
    //}
    //  else{this.notifier.notify('danger', 'Počkejte než Kontrolní kartu B zahájí kontrolor.')}
    },(error) => console.log(error)
    );
}

my_time(){
  var x = document.getElementById("myTime");
  var y = document.getElementById("currentTime");
  if (y.style.display === "none") {
    this.mytime = 0; console.log(this.mytime);
    y.style.display = "block";
    x.style.display = "none";
  } else {
    y.style.display = "none";
    x.style.display = "block";
    this.mytime = 1; console.log(this.mytime);
  }
}

}
