import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';

import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-udrzbaZaznam',
  templateUrl: './udrzbaZaznam.component.html',
  styleUrls: ['../../app.component.css']
})
export class UdrzbaZaznamComponent implements OnInit{


  today;
  datumStart;
  datumUkonceni;
  poznamka="";
  kategorie=[];
  Produkt={"id":""};
  zaznamUdrzby=[];
  zaznamUdrzbyPost={"id":"", "notes":"", "user_finish":"", "reference_notFinished":"", "timestamp_start":"", "timestamp_finish":"", "ip":"", "typ":"" };
  private notifier: NotifierService;

  constructor(private comm: CommService,
    private router:Router, private datePipe: DatePipe, notifier: NotifierService){
    this.notifier = notifier
  }

  ngOnInit() {
    this.datumStart=this.today;
    console.log(this.zaznamUdrzby);
    this.zaznamUdrzbyPost.notes='';
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.nacteniZarizeni();
    this.getKategorie();

    this.datumUkonceni = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm');
  
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  nacteniZarizeni(){
    this.comm.postInZaznam(this.comm.zaznamUdrzby).subscribe(
      (response) => {
        this.zaznamUdrzby=response.json();
        this.poznamka=this.zaznamUdrzby[0].notes;
        console.log("!!!");
        console.log(this.zaznamUdrzby);
        this.Produkt.id=this.zaznamUdrzbyPost.id;
        this.datumStart = this.datePipe.transform(this.zaznamUdrzby[0].timestamp_start, 'yyyy-MM-dd HH:mm');
        
      },
      (error) => console.log(error));
      return this.zaznamUdrzby;
  }

  onSubmit(form:NgForm){
    this.zaznamUdrzbyPost.user_finish=this.comm.getAuthUser().cislo;
    if (form.value.poznamka!=''){this.zaznamUdrzbyPost.notes=form.value.poznamka} else{this.zaznamUdrzbyPost.notes=this.zaznamUdrzby[0].poznamka};
    this.zaznamUdrzbyPost.reference_notFinished="0";
    this.zaznamUdrzbyPost.typ=this.Produkt.id;
    this.zaznamUdrzbyPost.timestamp_start=form.value.start;
    this.zaznamUdrzbyPost.timestamp_finish=form.value.finish;
    //this.zaznamUdrzbyPost.timestamp_finish=moment().format('YYYY-MM-DD HH:mm:ss');
    this.zaznamUdrzbyPost.id=this.zaznamUdrzby[0].udrzba_id;
    this.zaznamUdrzbyPost.ip=this.comm.ipAddress;

    console.log(this.zaznamUdrzbyPost);
   
    this.comm.updateUdrzba(this.zaznamUdrzbyPost).subscribe(
        (response) => {
          console.log(response);
          this.notifier.notify('success', 'Údržba uložena');
          this.router.navigate(['/tpm'])
          },
        (error) => console.log(error));
  }

  zpet(form:NgForm){
    this.zaznamUdrzbyPost.user_finish=null;
    console.log("POZNAMKATXTBX:"+form.value.poznamka);
    if (this.zaznamUdrzby[0].poznamka=form.value.poznamka){this.zaznamUdrzbyPost.notes=this.zaznamUdrzby[0].poznamka} else{this.zaznamUdrzbyPost.notes=form.value.poznamka};
    console.log("POZNAMKA:"+this.zaznamUdrzbyPost.notes);
    this.zaznamUdrzbyPost.id=this.zaznamUdrzby[0].udrzba_id;
    this.zaznamUdrzbyPost.ip=this.comm.ipAddress;
    this.zaznamUdrzbyPost.typ=this.Produkt.id;
    this.zaznamUdrzbyPost.timestamp_start=form.value.start;
    this.zaznamUdrzbyPost.timestamp_finish=null;
    this.zaznamUdrzbyPost.reference_notFinished="1";

    console.log(this.zaznamUdrzbyPost);
    
    
    this.comm.updateUdrzba(this.zaznamUdrzbyPost).subscribe(
      (response) => {
        console.log(response);
        this.notifier.notify('success', 'Zařazeno do rozpracovných');
        this.router.navigate(['/tpm'])
        },
      (error) => console.log(error));
  }

  smazat(){
    console.log(this.zaznamUdrzby[0]);
    this.comm.deleteUdrzba(this.zaznamUdrzby[0]).subscribe(
      (response) => {
        console.log(response);
        this.notifier.notify('danger', 'Udržba byla smazána');
        this.router.navigate(['/tpm'])
        },
      (error) => console.log(error));
  }


  getKategorie(){
    this.comm.getKategorieUdrzba().subscribe(
      (response) => {
        this.kategorie=response.json();
        console.log(this.kategorie);
      },
      (error) => console.log(error));
      return this.kategorie;
  }

}
