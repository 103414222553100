import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";



import { Router } from '@angular/router';

@Component({
  selector: 'app-udrzbaHistorie',
  templateUrl: './udrzbaHistorie.component.html',
  styleUrls: ['../../app.component.css']
})
export class UdrzbaHistorieComponent implements OnInit{


  today: number = Date.now();
  historieUdrzba=[];
  typyUdrzba=[];

  constructor(private comm: CommService,
    private router:Router){}

  ngOnInit() {
    this.typyUdrzba=this.comm.data_typyUdrzby;
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.nacteniHistorie();

  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  openTyp(j){
    this.comm.typyUdrzby.id=this.typyUdrzba[j].id;
    this.router.navigate(['/tpm/vyber']);
    console.log(this.typyUdrzba[j].id);
  }

  nacteniHistorie(){
    this.comm.getHistorieUdrzba().subscribe(
      (response) => {
        this.historieUdrzba=response.json();
        console.log(this.historieUdrzba);
      },
      (error) => console.log(error));
      return this.historieUdrzba;
  }

}
