import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';

@Component({
  selector: 'app-assy',
  templateUrl: './assy.component.html',
  styleUrls: ['../../app.component.css']
})
export class AssyComponent implements OnInit{
  itemclass4;
  assy=[];
  chybiKomponenta={"name":"", "pn":""};

  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){  this.notifier = notifier;}

  ngOnInit() {
    this.assy=this.comm.ps_assy;
    this.itemclass4 = "none";
    }

    getAuthUser() {
      return this.comm.getAuthUser()
    }

    materialcall(vybrano){
      this.itemclass4 = "block";
      this.chybiKomponenta.name=this.assy[vybrano].name;
      this.chybiKomponenta.pn=this.assy[vybrano].pn;
    }

    close(){
      this.itemclass4 = "none";
   }

   onSubmitChybikomponenta(){
    this.comm.chybiKomponenta.status=1;
    this.comm.chybiKomponenta.pn=this.chybiKomponenta.pn;
    this.comm.chybiKomponenta.smenaId=this.comm.zaznamSmeny.smenaId;
    this.comm.chybiKomponenta.osoba=this.comm.zaznamSmeny.osoba;
    console.log(this.comm.zaznamPoruchy);
    this.comm.postChybiKomponenta(this.comm.chybiKomponenta).subscribe(
      (response) => {
      console.log(response);
      this.itemclass4 = "none";
      this.notifier.notify('success', 'Žádost o komponentu byla odeslána')
       },
      (error) => console.log(error));
  }

}
