import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";



import { Router } from '@angular/router';

@Component({
  selector: 'app-kartaBHistorie',
  templateUrl: './kartaBHistorie.component.html',
  styleUrls: ['../../app.component.css']
})
export class KartaBHistorieComponent implements OnInit{

  today: number = Date.now();
  historieKartaB=[];
  typyPozice=[];

  constructor(private comm: CommService,
    private router:Router){}

  ngOnInit() {
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.nacteniHistorie();
    this.nacteniPozice();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  nacteniHistorie(){
    this.comm.getKB_history().subscribe(
      (response) => {
        this.historieKartaB=response.json();
        console.log(this.historieKartaB);
      },
      (error) => console.log(error));
      return this.historieKartaB;
  }

  nacteniPozice(){
    this.comm.getPozice().subscribe(
      (response) => {
        this.typyPozice=response.json();
        console.log(this.typyPozice);
      },
      (error) => console.log(error));
      return this.typyPozice;
  }

}
