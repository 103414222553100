import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";

import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-karta-a',
  templateUrl: './kartaA.component.html',
  styleUrls: ['../../app.component.css']
})
export class KartaAComponent implements OnInit{

  kartaA_refValue;
  kartaA_refValueU;
  kartaA_refValueL;

  kartaA_data={"smena_id":"","user_controlled":"","measure_data":"", "ip":""};
  private notifier: NotifierService;
  constructor(private comm: CommService, private router:Router, notifier: NotifierService){
    this.notifier = notifier}

  ngOnInit() {
    this.kartaA_refValue= Number(this.comm.ps_pn.kartaA_refValue);
    console.log(this.kartaA_refValue);
    this.kartaA_refValueU= Number(this.comm.ps_pn.kartaA_refValue) + Number(this.comm.ps_pn.kartaA_refValueplus);
    this.kartaA_refValueL= Number(this.comm.ps_pn.kartaA_refValue) - Number(this.comm.ps_pn.kartaA_refValueminus);
    console.log(this.kartaA_refValueU);
    console.log(this.kartaA_refValueL);
    }

    getAuthUser() {
      return this.comm.getAuthUser()
    }

    postKartaA(form:NgForm){
      if (form.value.user_controlled!=''){this.kartaA_data.user_controlled=form.value.user_controlled};
      if (form.value.measure_data!=''){this.kartaA_data.measure_data=form.value.measure_data};
      this.kartaA_data.measure_data=this.kartaA_data.measure_data.replace(/,/g, '.');
      this.kartaA_data.smena_id=this.comm.zaznamSmeny.smenaId;
      this.kartaA_data.ip=this.comm.ipAddress;
      console.log(this.kartaA_data);

      this.comm.postKartaA(this.kartaA_data).subscribe(
        (response) => {
          console.log(response);
          this.notifier.notify('success', 'Data odeslána');
          },
        (error) => {console.log(error); this.notifier.notify('danger', 'Chyba, data nebyla odeslána')});


      
    }

}
