import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";
import { Router } from '@angular/router';


@Component({
  selector: 'app-pn',
  templateUrl: './pn.component.html',
  styleUrls: ['../../app.component.css']
})
export class PnComponent implements OnInit{
  pnx=[];
  pn={"pn":"", "name":"", "image":"", "info":"", "kartaA":"", "stoplineSmena":"", 
  "pripravek_id":null}
  constructor(private comm: CommService, private router:Router){}

  ngOnInit() {
    this.pn.pn=this.comm.ps_pn.pn;
    this.pn.name=this.comm.ps_pn.name;
    this.pn.image=this.comm.ps_pn.image;
    this.pn.info=this.comm.ps_pn.info;
    this.pn.kartaA=this.comm.ps_pn.kartaA;
    this.pn.stoplineSmena=this.comm.ps_pn.stoplineSmena;

    this.pn.pripravek_id=this.comm.zaznamSmeny.pripravek;
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

}
