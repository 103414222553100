import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['../app.component.css']
})
export class AuditComponent implements OnInit{

  today: number = Date.now();


  constructor(private comm: CommService, private router:Router){}

  ngOnInit() {

    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

}
