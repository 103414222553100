import { Component, OnInit } from '@angular/core';
import { CommService } from "./comm.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title: string = '';
  constructor(private comm: CommService){}

  setHeader() {
      this.title=this.comm.getAuthUser().jmeno+" TABLETEC";
  }
}
