import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-lsa30',
  templateUrl: './lsa30.component.html',
  styleUrls: ['../../app.component.css']
})
export class LsaMesicniComponent {

  today: number = Date.now();
  vyr_proc=[];
  bozp=[];
  sklad=[];
  kvalita=[];
  typ: number = 30;
  auditId:{"auditId":null};
  delkaBunky;
  provoz={"provozovna":""};
  seznampn=[];
  provozovny=[];
  uzivatele=[];
  pnres={"id":null,};
  ProduktID;

  seconds=5;
  interval = null;

  itemclass;

  zaznamAudit={"typ":null, "auditId":null,"osoba":"", "operator":"", "pn":"", "komentar":"",  "question":null, "bod":"", "provoz":""};
  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){this.notifier = notifier}

  ngOnInit() {
    this.itemclass = "none";
    this.zaznamAudit.question="";
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.getauditid();
    this.getseznampn();
    this.getuzivatele();
    this.getprovozovny();
    this.getLPA30_vyr_proc();
    this.getLPA30_kvalita();
    this.getLPA30_sklad();
    this.getLPA30_bozp();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  getseznampn(){
    this.comm.getPn().subscribe(
      (response) => {
        this.seznampn=response.json();
        console.log(this.seznampn);
      },
      (error) => console.log(error));
      return this.seznampn;
  }

  getprovozovny(){
    this.comm.getProvozovny().subscribe(
      (response) => {
        this.provozovny=response.json();
        console.log(this.provozovny);
      },
      (error) => console.log(error));
      return this.provozovny;
  }

  getuzivatele(){
    this.comm.getUzivatele().subscribe(
      (response) => {
        this.uzivatele=response.json();
        console.log(this.uzivatele);
      },
      (error) => console.log(error));
      return this.uzivatele;
  }

  onSubmit(form:NgForm){
    this.delkaBunky=this.vyr_proc.length+this.kvalita.length+this.bozp.length+this.sklad.length;
    if (form.value.provoz=='' ){this.notifier.notify('error', 'Vyplňte číslo operátora, PN a provozovnu!');}else{
          this.ProduktID="-";
          console.log(this.ProduktID);
          var i;
          var k=this.vyr_proc[0].id;
          for (i = 0; i < this.delkaBunky; i++) {
          this.zaznamAudit.question=k;
          this.zaznamAudit.bod=form.value['q'+k];
          if (this.auditId[0].auditId!=null){this.zaznamAudit.auditId=this.auditId[0].auditId+1}else{this.zaznamAudit.auditId="0";};
          this.zaznamAudit.typ=this.typ;
          this.zaznamAudit.osoba=this.comm.getAuthUser().cislo;
          this.zaznamAudit.operator=form.value.operator;
          this.zaznamAudit.pn=this.ProduktID;
          this.zaznamAudit.provoz=form.value.provoz;
          this.zaznamAudit.komentar=form.value.komentar;
          console.log(this.zaznamAudit);
          k=k+1;
          this.comm.postZaznamAudit(this.zaznamAudit).subscribe(
              (response) => console.log(response),
              (error) => console.log(error));
          }
          this.loading();
      }
  }

  getauditid(){
    this.comm.getAuditId().subscribe(
      //(response) => console.log(response),
      (response) => {
        this.auditId=response.json();
        console.log(this.auditId);
      },
      (error) => console.log(error));
      return this.auditId;
  }

  getLPA30_vyr_proc(){
    this.comm.getLpa('vp', 30).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.vyr_proc=response.json();
        console.log(this.vyr_proc);
      },
      (error) => console.log(error));
      return this.vyr_proc;
  }
  getLPA30_kvalita(){
    this.comm.getLpa('kvalita', 30).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.kvalita=response.json();
        console.log(this.kvalita);
      },
      (error) => console.log(error));
      return this.kvalita;
  }
  getLPA30_bozp(){
    this.comm.getLpa('bozp', 30).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.bozp=response.json();
        console.log(this.bozp);
      },
      (error) => console.log(error));
      return this.bozp;
  }
  getLPA30_sklad(){
    this.comm.getLpa('sklad', 30).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.sklad=response.json();
        console.log(this.sklad);
      },
      (error) => console.log(error));
      return this.sklad;
  }

  loading(){
    this.itemclass = "block";

    this.seconds=5;
    this.interval = setInterval(() => {
      if(this.seconds >=0) {
        this.seconds--;
        if(this.seconds===0){
          this.router.navigate(['/dashboard']);
        }
      } else {
                clearInterval(this.interval);
              }
    }, 1000);
  }

}
