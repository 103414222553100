import { Component, OnInit } from '@angular/core';

import { CommService } from "../comm.service";
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-potvrzeni-smeny',
  templateUrl: './potvrzeniSmeny.component.html',
  styleUrls: ['../app.component.css']
})
export class PotvrzeniSmenyComponent implements OnInit{
celkemks="";
today: number = Date.now();

  constructor(private comm: CommService,

    private router:Router){}

    ngOnInit() {
      this.today = setInterval( () => {
        this.today = Date.now();
      }, 1000);
    }

    getAuthUser() {
      return this.comm.getAuthUser()
    }

    addToForm(value) {
        this.celkemks = this.celkemks.toString() + value.toString()
    }

    resetForm() {
        this.celkemks = ''
      }

    onSubmit(){
      this.comm.zs_zaznamsmeny.totalOK=parseInt(this.celkemks);
      this.comm.zs_zaznamsmeny.total=parseInt(this.comm.zs_zaznamsmeny.totalOK) + parseInt(this.comm.zs_zaznamsmeny.totalNOK);
      this.comm.zs_zaznamsmeny.NokOk=(this.comm.zs_zaznamsmeny.totalNOK / this.comm.zs_zaznamsmeny.total)*100;

      console.log(this.comm.zs_zaznamsmeny);
      this.router.navigate(['/potvrzeni']);
    }
}
