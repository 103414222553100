import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-udrzba',
  templateUrl: './udrzba.component.html',
  styleUrls: ['../app.component.css']
})
export class UdrzbaComponent implements OnInit{

  today: number = Date.now();
  nedokoncenaUdrzba=[];
  poruchy=[];
  typyUdrzba=[];
  itemclass3;
  responseInsert;
  zaznamUdrzbyPost={"typUdrzby_id":"", "zarizeni_id":"", "notes":"", "user_start":"", "user_finish":"", "reference_notFinished":"", "timestamp_finish":"", "ip":"" };
  

  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){  this.notifier = notifier;}

  ngOnInit() {
    this.nacteniTypyUdrzby();
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.nacteniRozpracovane();
    this.nacteniPoruchy();
    this.infinite();
    this.itemclass3 = "none";
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  nacteniTypyUdrzby(){
    this.comm.getTypyUdrzba().subscribe(
      (response) => {
        this.typyUdrzba=response.json();
        this.comm.data_typyUdrzby=this.typyUdrzba;
        console.log(this.typyUdrzba);
      },
      (error) => console.log(error));
      return this.typyUdrzba;
  }

  openTyp(j){
    this.comm.typyUdrzby.id=this.typyUdrzba[j].id;
    this.router.navigate(['/tpm/vyber']);
    console.log(this.typyUdrzba[j].id);
  }

  nacteniRozpracovane(){
    this.comm.getNedokoncene().subscribe(
      (response) => {
        this.nedokoncenaUdrzba=response.json();
        console.log(this.nedokoncenaUdrzba);
      },
      (error) => console.log(error));
      return this.nedokoncenaUdrzba;
  }

  nacteniPoruchy(){
    this.comm.getPoruchy().subscribe(
      (response) => {
        this.poruchy=response.json();
        console.log(this.poruchy);
      },
      (error) => console.log(error));
      return this.poruchy;
  }


  openZaznam(vybraneid){
    this.comm.id=this.nedokoncenaUdrzba[vybraneid].id;
    this.comm.zaznamUdrzby.id=this.nedokoncenaUdrzba[vybraneid].udrzba_id;
    this.router.navigate(['/tpm/zaznam']);
  }

  selectPoruchy(vybraneid){
  this.comm.zaznamPoruchy.id=this.poruchy[vybraneid].id;
  this.fehler();
  }

  fehler(){
    this.itemclass3 = "block";
  }

  close(){
    this.itemclass3 = "none";
 }

 infinite(){
  setInterval( () => {this.nacteniPoruchy();}, 10000);
 }
  
  onSubmitFehler(){
    this.comm.zaznamPoruchy.status=0;
    this.comm.zaznamPoruchy.timestamp_finish=moment().format('YYYY-MM-DD HH:mm:ss');
    this.comm.zaznamPoruchy.user_finish=this.comm.getAuthUser().cislo;
    console.log(this.comm.zaznamPoruchy);
    this.comm.updatePoruchy(this.comm.zaznamPoruchy).subscribe(
      (response) => {
      console.log(response);
      this.itemclass3 = "none";
      this.notifier.notify('success', 'Po seřízení vyplňte záznam údržby.');
      this.nacteniPoruchy();
      this.router.navigate(['/tpm']);
       },
      (error) => console.log(error));
  }

  //priprava pro vyskakovaci okno k zavolani udrzby. Zatim je fixováno na sestavu a tak neni mozne proradit udrzbu k zarizeni
  novyZaznamUdrzba(){
    //this.comm.zaznamUdrzby.id=;
    this.zaznamUdrzbyPost.user_start=this.comm.getAuthUser().cislo;
    this.zaznamUdrzbyPost.user_finish=null;
    this.zaznamUdrzbyPost.timestamp_finish=null;
    this.zaznamUdrzbyPost.notes=null;
    this.zaznamUdrzbyPost.reference_notFinished="1";
    this.zaznamUdrzbyPost.ip=this.comm.ipAddress;
    
    this.comm.insertUdrzba(this.zaznamUdrzbyPost).subscribe(
      (response) => {
        this.responseInsert=response.json();
        this.comm.zaznamUdrzby.id=this.responseInsert.insertId;
        this.router.navigate(['/tpm/zaznam'])
      },
      (error) => console.log(error));
  }

}
