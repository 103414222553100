import { Component, OnInit } from '@angular/core';

import { CommService } from "../comm.service";
import { Router } from '@angular/router';


@Component({
  selector: 'app-potvrzeni',
  templateUrl: './potvrzeni.component.html',
  styleUrls: ['../app.component.css']
})
export class PotvrzeniComponent implements OnInit{
  seconds=10;
  zaznamsmeny={"totalOK":"","totalNOK":""};
  interval = null;
    constructor(private comm: CommService,

      private router:Router){}

      ngOnInit() {
        this.zaznamsmeny.totalOK=this.comm.zs_zaznamsmeny.totalOK;
        this.zaznamsmeny.totalNOK=this.comm.zs_zaznamsmeny.totalNOK;
        this.interval = setInterval(() => {
          this.odecet();
          console.log("jede");
        }, 1000);
      }

      getAuthUser() {
        return this.comm.getAuthUser()
      }

      odecet(){
        if(this.seconds >=0) {
          this.seconds--;
          this.potvrzeni();
          console.log("Odečet");
          console.log(this.seconds);
        } else {
                  clearInterval(this.interval);
                }
      }

      potvrzeni(){
        if(this.seconds==0){
          console.log(this.comm.zs_zaznamsmeny);
          this.comm.postUpdateZaznamSmena(this.comm.zs_zaznamsmeny).subscribe(
          (response) => console.log(response),
          (error) => console.log(error));
          this.comm.logout()
          return true;
        }
      }
        //this.freq=this.vybraneZavady[vybraneid].freq+1;
        //console.log("FREQ:"+this.freq);
        //this.comm.postFrekvenceZavady(this.freq).subscribe(
        //(response) => console.log(response),
        //(error) => console.log(error));
        zpet(){
          this.seconds=null;
          this.router.navigate(['/zavady'])
        }

  }
