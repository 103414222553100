import { Injectable, ApplicationRef } from '@angular/core';
import { Headers, RequestOptions, Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier'
import * as tools from 'src/app/tools'
import { Observable, Subject, ReplaySubject, from, of, range } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommService {

  authenticated:boolean=true;
  usermail:string="neprihlasen";
  selectednastaveni:string;

  // API location
  // path:string="http://localhost:9999"; // local
  path:string="https://api-tablet.appkampos.cz"; // dev
  // path:string="http://194.182.68.12:9999"; // production

  ipAddress;
  smena:any=[{"smenaId":""}];
  zaznamSmeny={"sestavy_id":null, "batch":null, "osoba":null, "pn":null, "total":null, "ip":"", "normohodina":null, "pripravek":null, smenaId:null};
  pn={"id":null, "pn":"", "name":"", "image":"", "stoplinePoSobe":"",
  "stoplineSmena":"", "vykres":"", "pracPokyny":"", "kontPokyny":"", "merePokyny":"", "normohodina":null};
  vyberPn={"pn":"",};
  assy=[{"name":"", "image":""}];
  zaznamAudit={"typ":"", "operator":"", "pn":"", "komentar":"", "osoba":"", "q1":"", "q2":"",
  "q3":"", "q4":"", "q5":"", "q6":"", "q7":"", "q8":"", "q9":"", "q10":"", "q11":"", "q12":"",
  "q13":"", "q14":"", "q15":"", "q16":"", "q17":"", "q18":"", "q19":"", "q20":""};

  zaznamPoruchy={"smenaId":"", "osoba":"", "status":null, "timestamp_finish":null, "user_finish":"", "id":null, "note":""};
  chybiKomponenta={"smenaId":"", "pn":"", "osoba":"", "status":null, "timestamp_finish":null, "user_finish":"", "id":null, "note":""};

  // from ZavadyService
  zs_pn={"id":null, "pn":"", "name":"", "image":"", "stoplinePoSobe":"",
  "stoplineSmena":"", "vykres":"", "pracPokyny":"", "kontPokyny":"", "merePokyny":""};
  zavadyk=[];
  zavadyv=[];
  zavadyt=[];
  zaznam={"id":null, "zavadaID":"", "name":"", "pn":"", "osoba":"", "batch":"", "idSmena":"", "ip":""};
  zs_zaznamsmeny={"smenaId":"", "osoba":"", "batch":"", "pn":"", "total":null, "totalNOK":null, "totalOK":null, "NokOk":null, "ip":"", "normohodina":null, "pripravek":""};
  zavady=0;

  // from ProductService
  ps_pn={"id":null, "pn":"", "name":"", "image":"", "stoplinePoSobe":"",
  "stoplineSmena":null, "vykres":"", "pracPokyny":"", "kontPokyny":"", "merePokyny":"", "info":"", "kartaA":null, "kartaB":null,
  "kartaA_refValue":"", "kartaA_refValueplus":"", "kartaA_refValueminus":"", "normohodina":null};
  ps_vyberPn={"pn":"",};
  ps_assy=[{"name":"", "image":""}];
  ps_prubeh=[];
  ps_summ:number;

  // from UdrzbaService
  data_typyUdrzby=[];
  typyUdrzby={"id":""};
  zaznamUdrzby={"id":"", "user_start":"" ,"user_finish":"", "timestamp_start":Date, "timestamp_finish":Date,
  "zarizeni_id":"", "typUdrzby_id":"", "notes":"", "reference_notFinished":"",
  "ip":""};

  control_pozice={"type":""};
  control_id={"smenaId":""};


  id="";
  idd={"id":""}
  vybraneid="";
  udrzbaId="";
  udrzbaID={"udrzbaID":""};

  typ={"typ":""};
  seznamZarizeni=[];

  public authUser:any = {};
  private authToken:string;
  private notifier: NotifierService;
  private options: RequestOptions = null;

  constructor(private http: Http, private router:Router, notifier: NotifierService, private appRef: ApplicationRef) {
    this.notifier = notifier
    // revalidation
    this.revalidate()
  }

  // AUTH methods --------------------------------------------

  checkCislo(cislo) {
    return this.http.get(this.path+'/checkCislo/' + cislo.toString())
  }

  login(data) {
    return this.http.post(this.path+'/login', data)
  }

  me() {
    return this.http.get(this.path+'/me', this.options)
  }

  isLoggedIn() {
    return !!this.authUser.id
  }

  getAuthUser() {
    return this.authUser
  }

  saveUser(user) {
    this.authUser = user
  }

  logout() {
    this.authUser = {}
    this.authToken = ''
    tools.deleteCookie('AuthToken')
    this.refreshOptions()
    this.router.navigate(['/login'])
  }

  refreshOptions() {
    let headers = new Headers();
    if (this.authToken) {
      headers.append('X-Auth-Token', `${this.authToken}`);
    }
    this.options = new RequestOptions({headers});
  }

  saveToken(token, remember = 1) {
    this.authToken = token
    // save in cookies for revalidation
    const expirationMinutes = remember ? 60 * 24 * 14 : 60
    tools.setCookie('AuthToken', token, expirationMinutes)
    this.refreshOptions()
  }

  revalidate() {
    const token = tools.getCookie('AuthToken')
    if (token) {
      this.authToken = token
      this.refreshOptions()
      this.me().subscribe(
        (response: any) => {
          const responseData = response.json()
          if (responseData && responseData.error && !responseData.id) {
            console.error({responseData})
            this.notifier.notify('error', 'Přihlášení vypršelo, je nutné se přihlásit znovu')
            this.router.navigate(['/login'])
          } else {
            this.saveUser(responseData)
            if (this.router.url === '/login') {
              // revalidation on login page -> move to dashboard
              this.router.navigate(['/dashboard'])
            }
            this.appRef.tick() // trigger change
          }
        },
        (error) => {
          this.notifier.notify('error', 'Chyba při opětovném přihlášení, je nutné se přihlásit znovu')
          this.router.navigate(['/login'])
          console.error({error})
        }
      )
    } else {
      // simply not logged in
      this.router.navigate(['/login'])
    }
  }

  // can the currently logged in user do this?
  can(how = "read", what = "") {
    if (!(this.authUser || {}).id) {
      return false // not logged in = cannot do anything
    }
    if (this.authUser.isAdmin) {
      return true // admin can do anything
    }
    if (!what) {
      return true // just logged in
    }
    const perm = this.authUser.permissions.find(p => p.permission === what)
    if (!perm) {
      return false // lacking the permission altogether
    }
    return !!perm[how] // cast to boolean
  }

  getOvereniUzivatele(uzivatel){
    return this.http.post(this.path+'/getovereniuzivatele', uzivatel, this.options)
  }

  getOnline(){
    return this.http.get(this.path+'/getonline', this.options)
  }

  //SCHICHT
  getStatusSmena(produkt){
    return this.http.post(this.path+'/getschichtstatus', produkt, this.options)
  }

  getHistorySmena(produkt){
    return this.http.post(this.path+'/getschichthistory', produkt, this.options)
  }

  getPosledniSmena(uzivatel){
    return this.http.post(this.path+'/getposlednismena', uzivatel, this.options)
  }

  getNovaSmenaId(){
    return this.http.get(this.path+'/getnovasmenaid', this.options)
  }

  postNovyZaznamSmena(produkt){
    return this.http.post(this.path+'/novyzaznamsmeny', produkt, this.options)
  }

  getpnvyber(pn){
    return this.http.post(this.path+'/getpnvyber', pn, this.options)
  }

  getPn(){
    return this.http.get(this.path+'/getpn', this.options)
  }

  getKomponenty(produkt){
    return this.http.post(this.path+'/getkomponenty', produkt, this.options)
  }

  postZaznamPoruchy(produkt){
    return this.http.post(this.path+'/postzaznamporuchy', produkt, this.options)
  }

  postChybiKomponenta(produkt){
    return this.http.post(this.path+'/postchybikomponenta', produkt, this.options)
  }

  getZarizeni() {
    return this.http.get(this.path+'/getzarizeni', this.options)
  }

  //AUDITY

  getProvozovny(){
    return this.http.get(this.path+'/getprovozovny', this.options)
  }

  getUzivatele(){
    return this.http.get(this.path+'/getuzivatele', this.options)
  }

  getAuditId() {
    return this.http.get(this.path+'/getauditid', this.options)
  }

  postZaznamAudit(produkt){
    return this.http.post(this.path+'/postzaznamaudit', produkt, this.options)
  }

  getLpa(oblastShort, typ){
    const opts = {...this.options, params: {oblastShort, typ}}
    return this.http.get(this.path+'/getlpa', opts)
  }

  /* from ZavadyService */

  postKartaA(produkt){
    return this.http.post(this.path+'/postkartaa', produkt, this.options)
  }

  postKartaB(produkt){
    return this.http.post(this.path+'/postkartab', produkt, this.options)
  }

  postKartaBX(produkt){
    return this.http.post(this.path+'/postkartabx', produkt, this.options)
  }

  postkartaB_check(produkt){
    return this.http.post(this.path+'/postkartab_check', produkt, this.options)
  }

  postPrubehSmeny(produkt){
    return this.http.post(this.path+'/postprubehsmeny', produkt, this.options)
  }

  getZavadyK(id){
    return this.http.post(this.path+'/getzavadyk', id, this.options)
  }

  getZavadyV(id){
    return this.http.post(this.path+'/getzavadyv', id, this.options)
  }

  getZavadyT(id){
    return this.http.post(this.path+'/getzavadyt', id, this.options)
  }

  postZaznamZavady(produkt){
    return this.http.post(this.path+'/novyzaznamzavady', produkt, this.options)
  }

  getPocetZavad(produkt){
    return this.http.post(this.path+'/getpocetzavadsmeny', produkt, this.options)
  }

  postUpdateZaznamSmena(produkt){
    return this.http.post(this.path+'/updatezaznamsmeny', produkt, this.options)
  }

  /* from UdrzbaService */
  getNedokoncene(){
    return this.http.get(this.path+'/udrzba_getnedokoncene', this.options)
  }

  getTypyUdrzba(){
    return this.http.get(this.path+'/udrzba_gettypyzarizeni', this.options)
  }

  getKategorieUdrzba(){
    return this.http.get(this.path+'/udrzba_gettypyudrzby', this.options)
  }

  postZarizeni(typ){
    return this.http.post(this.path+'/udrzba_postzarizeni', typ, this.options)
  }

  getHistorieUdrzba(){
    return this.http.get(this.path+'/udrzba_getprobehle', this.options)
  }

  postInZaznam(id){
    return this.http.post(this.path+'/udrzba_postinzaznam', id, this.options)
  }

  deleteUdrzba(produkt){
    return this.http.post(this.path+'/udrzba_delete', produkt, this.options)
  }

  updateUdrzba(produkt){
    return this.http.post(this.path+'/udrzba_update', produkt, this.options)
  }

  insertUdrzba(produkt){
    return this.http.post(this.path+'/udrzba_insert', produkt, this.options)
  }

  getPoruchy(){
    return this.http.get(this.path+'/getporuchy', this.options)
  }

  updatePoruchy(produkt){
    return this.http.post(this.path+'/poruchy_update', produkt, this.options)
  }
  // Logistika

  getChybiKomponenta(){
    return this.http.get(this.path+'/getchybikomponenta', this.options)
  }

  updateChybiKomponenta(produkt){
    return this.http.post(this.path+'/chybikomponenta_update', produkt, this.options)
  }

  // Kontrola

  getPozice(){
    return this.http.get(this.path+'/getpozice', this.options)
  }

  postControl(typ){
    return this.http.post(this.path+'/getcontrol', typ, this.options)
  }

  postKontrolniRozmery(typ){
    return this.http.post(this.path+'/getkontrolnirozmery', typ, this.options)
  }

  getKB_history(){
    return this.http.get(this.path+'/getkbhistory', this.options)
  }

  getKA_history(){
    return this.http.get(this.path+'/getkahistory', this.options)
  }

  postNamereneRozmerySmena(typ){
    return this.http.post(this.path+'/getmeasurehistory', typ, this.options)
  }
  
  

}
