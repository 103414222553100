import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-lsa1',
  templateUrl: './lsa1.component.html',
  styleUrls: ['../../app.component.css']
})
export class LsaDenniComponent implements OnInit{

  today: number = Date.now();
  vyr_proc=[];
  zaz_vyr=[];
  sssss=[];
  auditId:{"auditId":null};
  bozp=[];
  typ: number = 1;
  delkaBunky;
  provoz={"provozovna":null};
  seznampn=[];
  provozovny=[];
  uzivatele=[];
  pnres={"id":null,};
  ProduktID;

  seconds=5;
  interval = null;

  itemclass;

  zaznamAudit={"typ":null, "auditId":null,"osoba":"", "operator":"", "pn":"", "komentar":"",  "question":null, "bod":"", "provoz":""};
  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){this.notifier = notifier}

  ngOnInit() {
    this.itemclass = "none";
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.getauditid();
    this.getseznampn();
    this.getuzivatele();
    this.getprovozovny();
    this.getLPA1_vyr_proc();
    this.getLPA1_zaz_vyr();
    this.getLPA1_5s();
    this.getLPA1_bozp();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  getseznampn(){
    this.comm.getPn().subscribe(
      (response) => {
        this.seznampn=response.json();
        console.log(this.seznampn);
      },
      (error) => console.log(error));
      return this.seznampn;
  }

  getprovozovny(){
    this.comm.getProvozovny().subscribe(
      (response) => {
        this.provozovny=response.json();
        console.log(this.provozovny);
      },
      (error) => console.log(error));
      return this.provozovny;
  }

  getuzivatele(){
    this.comm.getUzivatele().subscribe(
      (response) => {
        this.uzivatele=response.json();
        console.log(this.uzivatele);
      },
      (error) => console.log(error));
      return this.uzivatele;
  }

  onSubmit(form:NgForm){
    this.delkaBunky=this.vyr_proc.length+this.zaz_vyr.length+this.sssss.length+this.bozp.length;
    if (form.value.operator=='' || form.value.pn=='' || form.value.provoz=='' ){this.notifier.notify('error', 'Vyplňte číslo operátora, PN a provozovnu!');}else{
      this.comm.getpnvyber(form.value).subscribe(
        (response) => {
          this.pnres=response.json();
          this.ProduktID=this.pnres[0].id;
          console.log(this.ProduktID);
          var i;
          var k=this.vyr_proc[0].id;
          for (i = 0; i < this.delkaBunky; i++) {
          this.zaznamAudit.question=k;
          this.zaznamAudit.bod=form.value['q'+k];
          if (this.auditId[0].auditId!=null){this.zaznamAudit.auditId=this.auditId[0].auditId+1}else{this.zaznamAudit.auditId="0";};
          this.zaznamAudit.typ=this.typ;
          this.zaznamAudit.osoba=this.comm.getAuthUser().cislo;
          this.zaznamAudit.operator=form.value.operator;
          this.zaznamAudit.pn=this.ProduktID;
          this.zaznamAudit.provoz=form.value.provoz;
          this.zaznamAudit.komentar=form.value.komentar;
          console.log(this.zaznamAudit);
          k=k+1;
          this.comm.postZaznamAudit(this.zaznamAudit).subscribe(
              (response) => console.log(response),
              (error) => console.log(error));
          }
          this.loading();
        },
        (error) => console.log(error));
      }
  }

  getauditid(){
    this.comm.getAuditId().subscribe(
      //(response) => console.log(response),
      (response) => {
        this.auditId=response.json();
        console.log(this.auditId);
      },
      (error) => console.log(error));
      return this.auditId;
  }

  getLPA1_vyr_proc(){
    this.comm.getLpa('vp', 1).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.vyr_proc=response.json();
        console.log(this.vyr_proc);
      },
      (error) => console.log(error));
      return this.vyr_proc;
  }
  getLPA1_zaz_vyr(){
    this.comm.getLpa('zv', 1).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.zaz_vyr=response.json();
        console.log(this.zaz_vyr);
      },
      (error) => console.log(error));
      return this.zaz_vyr;
  }
  getLPA1_5s(){
    this.comm.getLpa('5s', 1).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.sssss=response.json();
        console.log(this.sssss);
      },
      (error) => console.log(error));
      return this.sssss;
  }
  getLPA1_bozp(){
    this.comm.getLpa('bozp', 1).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.bozp=response.json();
        console.log(this.bozp);
      },
      (error) => console.log(error));
      return this.bozp;
  }

  loading(){
    this.itemclass = "block";

    this.seconds=5;
    this.interval = setInterval(() => {
      if(this.seconds >=0) {
        this.seconds--;
        if(this.seconds===0){
          this.router.navigate(['/dashboard']);
        }
      } else {
                clearInterval(this.interval);
              }
    }, 1000);
  }

}
