import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";

import {NgForm} from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-karta-b',
  templateUrl: './kartaB.component.html',
  styleUrls: ['../../app.component.css']
})
export class KartaBComponent implements OnInit {

  kartaB_data={"smena_id":"", "operator":"",
  "operator_note":"", "operator_visual":null, "operator_place":null, "operator_training":null,
  "ip":""};
  itemclass5;

  private notifier: NotifierService;
  constructor(private comm: CommService, notifier: NotifierService){
    this.notifier = notifier
  }

  ngOnInit() {
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  postKartaB(form:NgForm){
    console.log('form.value', form.value)
    if (form.value.operator_note!=''){this.kartaB_data.operator_note=form.value.operator_note};

    if (form.value['operator_visual']==true){this.kartaB_data.operator_visual=1} else{this.kartaB_data.operator_visual=0};
    if (form.value['operator_place']==true){this.kartaB_data.operator_place=1} else{this.kartaB_data.operator_place=0};
    if (form.value['operator_training']==true){this.kartaB_data.operator_training=1} else{this.kartaB_data.operator_training=0};

    this.kartaB_data.operator=this.comm.getAuthUser().jmeno;
    this.kartaB_data.smena_id=this.comm.zaznamSmeny.smenaId;
    console.log(this.kartaB_data);

    //this.comm.postKartaBX(this.kartaB_data).subscribe(
     // (response) => {
     //   console.log(response);
     //   this.notifier.notify('success', 'Data odeslána');
     //   form.value['operator_visual']==false;
     //   form.value['operator_place']==false;
     //   form.value['operator_training']==false;
     //   form.value.operator_note="";
     // },
     // (error) => {console.log(error); this.notifier.notify('danger', 'Chyba, data nebyla odeslána')
   // });
  }
  
  close(){
    this.itemclass5 = "none";
 }
}
