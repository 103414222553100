import { Component, OnInit } from '@angular/core';

import { CommService } from "../comm.service";
import { Router } from '@angular/router';


@Component({
  selector: 'app-potvrzeniAudit',
  templateUrl: './potvrzeniAudit.component.html',
  styleUrls: ['../app.component.css']
})
export class PotvrzeniAuditComponent implements OnInit{
  seconds=10;
  interval = null;
    constructor(private comm: CommService,

      private router:Router){}

      ngOnInit() {
        this.comm.zs_zaznamsmeny;
        this.interval = setInterval(() => {
          this.odecet();
          console.log("jede");
        }, 1000);
      }

      getAuthUser() {
        return this.comm.getAuthUser()
      }

      odecet(){
        if(this.seconds >=0) {
          this.seconds--;
          this.potvrzeni();
          console.log("Odečet");
          console.log(this.seconds);
        } else {
                  clearInterval(this.interval);
                }
      }

      potvrzeni(){
        if(this.seconds==0){
          this.router.navigate(['/dashboard']);
        }
      }

  }
