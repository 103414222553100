import { Component, OnChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommService } from "../../comm.service";
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-zmetkovitoststat',
  templateUrl: './stav.component.html',
  styleUrls: ['../../app.component.css']
})
export class ZmetkovitostStatComponent implements OnChanges{
  @Input() prubeh=[]; pocet;

constructor(private comm: CommService,
  private router:Router){}

ngOnChanges(...args: any[]) {
  this.prubeh;
  this.pocet=this.comm.zavady;
}

getAuthUser() {
  return this.comm.getAuthUser()
}


}
