import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommService } from "../../comm.service";



@Component({
  selector: 'app-zavadyvyber',
  templateUrl: './vyber.component.html',
  styleUrls: ['../../app.component.css']
})
export class ZavadyVyberComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  seznampn=[];
  Produkt={"pn":""};
  prefix_batch="PK/2020/";
  Batch;
  poslednismena=[];
  pn=[];
  assy=[];

  today: number = Date.now();

  constructor(private comm: CommService,

    private router:Router){}

  ngOnInit() {

    

    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.getseznampn();
    this.Batch = this.prefix_batch;
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  getseznampn(){
    this.comm.getPn().subscribe(
      (response) => {
        this.seznampn=response.json();
        document.getElementById("polozka").style.backgroundColor = "#E1F4DE";
      },
      (error) => console.log(error));
      return this.seznampn;
  }

  onSubmit(Produkt, Batch){
    this.comm.pn.pn=this.Produkt.pn;
    //console.log(this.comm.pn.pn);
    if (this.comm.pn.pn==""){alert("Vyber PN.")}
    else{this.getProduct();}
    }

    getProduct(){
      this.comm.getpnvyber(this.comm.pn).subscribe(
        (response) => {
          this.pn=response.json();
          this.comm.ps_pn=this.pn[0];
          console.log("PRODUKT");
          console.log(this.comm.ps_pn);
          this.getkomponenty();
          this.getzavadyk();
          this.getzavadyv();
          this.getzavadyt();
          this.schicht_neu();
          //this.novasmena();
        },
        (error) => console.log(error));
        return this.pn;
    }

    getkomponenty(){
      this.comm.getKomponenty(this.comm.ps_pn).subscribe(
        (response) => {
          this.comm.ps_assy=response.json();
          console.log("ASSY");
          console.log(this.comm.ps_assy);
        },
        (error) => console.log(error));
        return this.comm.ps_assy;
    }

    getzavadyk(){
      this.comm.getZavadyK(this.comm.ps_pn).subscribe(
        (response) => {
          this.comm.zavadyk=response.json();
          console.log("ZAVADY K");
          console.log(this.comm.zavadyk);
        },
        (error) => console.log(error));
        return this.comm.zavadyk;
    }

    getzavadyv(){
      this.comm.getZavadyV(this.comm.ps_pn).subscribe(
        (response) => {
          this.comm.zavadyv=response.json();
          console.log("ZAVADY V");
          console.log(this.comm.zavadyv);
        },
        (error) => console.log(error));
        return this.comm.zavadyv;
    }

    getzavadyt(){
      this.comm.getZavadyT(this.comm.ps_pn).subscribe(
        (response) => {
          this.comm.zavadyt=response.json();
          console.log("ZAVADY T");
          console.log(this.comm.zavadyt);
        },
        (error) => console.log(error));
        return this.comm.zavadyt;
    }

    schicht_neu(){
      this.comm.zaznamSmeny.smenaId=this.poslednismena[0].smenaid+1;
      this.comm.zaznamSmeny.pn=this.Produkt.pn;
      this.comm.zaznamSmeny.osoba=this.comm.getAuthUser().cislo;
      this.comm.zaznamSmeny.batch=this.Batch;
      this.comm.zaznamSmeny.ip=this.comm.ipAddress;
      this.comm.postNovyZaznamSmena(this.comm.zaznamSmeny).subscribe(
           (response) => {
             console.log(response);
             console.log("-> 1 <-");
             this.router.navigate(['/zavady']);
           },
           (error) => console.log(error));
   }

    novasmena(){
      this.comm.getNovaSmenaId().subscribe(
        (response) => {
          this.poslednismena=response.json();
          this.comm.zaznamSmeny.smenaId=this.poslednismena[0].smenaid+1;
          this.comm.zaznamSmeny.pn=this.Produkt.pn;
          this.comm.zaznamSmeny.osoba=this.comm.getAuthUser().cislo;
          this.comm.zaznamSmeny.batch=this.Batch;
          this.comm.zaznamSmeny.ip=this.comm.ipAddress;

          console.log("smena id (COM):"+this.comm.zaznamSmeny.smenaId);
          console.log("batch (COM):"+this.comm.zaznamSmeny.batch);
          console.log("osoba (COM):"+this.comm.zaznamSmeny.osoba);
          console.log("pn (COM):"+this.comm.zaznamSmeny.pn);


          this.comm.postNovyZaznamSmena(this.comm.zaznamSmeny).subscribe(
            (response) => {
              console.log(response);
              console.log("-> 1 <-");
              this.router.navigate(['/zavady']);
            },
            (error) => console.log(error));
        },
        (error) => console.log(error));
    }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.seznampn.filter(option => option.toLowerCase().includes(filterValue));
  }
}
