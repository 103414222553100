import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { CommService } from "../comm.service";
import { Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../app.component.css']
})
export class LoginComponent implements OnInit {
  ipAddress:any;
  online=[];
  cislo:string="";
  cisloVerified:boolean=false;
  password:string="";
  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, private http: HttpClient, notifier: NotifierService){
    this.notifier = notifier
    //this.http.get<{ip:string}>('https://jsonip.com')
    //.subscribe( data => {
      //console.log('th data', data);
    //  this.comm.ipAddress = data.ip
    //})
  }

  ngOnInit(){
    //this.getOnline();
  }

  getOnline(){
    this.comm.getOnline().subscribe(
      (response) => {
        this.online=response.json();
      },
      (error) => console.log(error));
      return this.online;
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  resetForm() {
    if (this.cisloVerified) {
      if (!this.password) {
        this.cislo = ''
        this.cisloVerified = false
      } else {
        this.password = ''
      }
    } else {
      this.cislo = ''
    }
  }
  setCislo(value) {
    this.cislo = value
    this.cisloVerified = false
  }
  setPassword(value) {
    this.password = value
  }
  addToForm(value) {
    if (this.cisloVerified) {
      this.password = this.password.toString() + value.toString()
    } else {
      this.cislo = this.cislo.toString() + value.toString()
    }
  }

  onSubmit() {
    if (this.cisloVerified) {
      if (!this.password) {
        this.notifier.notify('error', 'Zadejte heslo')
        return
      }
      this.login()
    } else {
      if (!this.cislo) {
        this.notifier.notify('error', 'Zadejte osobní číslo')
        return
      }
      this.check()
    }
  }

  check() {
    console.log(this.cislo);
    this.comm.checkCislo(this.cislo).subscribe(
      (response) => {
        const {exists} = response.json() || {exists: 0}
        if (!exists) {
          this.notifier.notify('error', 'Toto číslo neexistuje')
        } else {
          this.cisloVerified = exists
        }
      },
      (error) => console.error({error})
    )
  }

  login() {
    this.comm.login({cislo: this.cislo, password: this.password}).subscribe(
      (response) => {
        const responseData = response.json()
        const {token, user, error = false, message = ''} = responseData
        if (error) {
          this.notifier.notify('error', 'Chyba při přihlašování: ' + message)
          console.error({error})
          return
        }
        this.comm.saveUser(user)
        this.comm.saveToken(token, 0)
        this.notifier.notify('success', 'Úspěšné přihlášení')
        this.router.navigate(['/dashboard'])
      },
      (error) => {
        this.notifier.notify('error', 'Chyba při přihlašování')
        console.error({error})
      },
    )
  }
}
