import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { NotifierService } from 'angular-notifier';

//SELECT AZ.smenaId, AZ.osoba, AZ.batch, AZ.pn, AZ.dateZacatku, UZ.pozice, UZ.jmeno, SE.kartaB, SE.kartaA, SE.image, MAX(KB.timestamp) AS KartaB_timestamp, MAX(KA.timestamp) AS KartaA_timestamp FROM analyzaZavad AZ LEFT JOIN uzivatele UZ ON AZ.osoba=UZ.cislo LEFT JOIN sestavy SE ON AZ.pn=SE.pn LEFT JOIN zaznamKartaB KB ON AZ.smenaId=KB.smena_id LEFT JOIN zaznamKartaA KA ON AZ.smenaId=KA.smena_id LEFT JOIN zaznamZavady ZA ON AZ.smenaId=ZA.idSmena WHERE AZ.total IS NULL AND AZ.totalNOK IS NULL AND UZ.pozice="2" GROUP BY AZ.smenaId ORDER BY KartaB_timestamp ASC

@Component({
  selector: 'app-kontrola',
  templateUrl: './kontrola.component.html',
  styleUrls: ['../app.component.css']
})
export class KontrolaComponent implements OnInit{
  document;
  today: number = Date.now();
  currentData=[];
  typyPozice=[];
  itemclass;
  itemclass2;
  historieKartaA=[];
  j;
  zaznam={"pn":"", "batch":"", "osoba":"", "jmeno":"", "check_visual":null, "check_place":null, "check_training":null, "note":"", "date":"", "time":"", "datum":"", "smena_id":"", "identifier2":""};
  kontrolniRozmery=[];
  zaznamRozmer={"user_controlled":"", "smena_id":"", "measure_data":"", "id":null}


  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){this.notifier = notifier}


  ngOnInit() {
    this.itemclass = "none";
    this.itemclass2 = "none";
    this.nacteniPozice();
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.comm.control_pozice.type="2";
    this.nacteniCurrent();
    this.zaznam.check_visual=0;
    this.zaznam.check_place=0;
    this.zaznam.check_training=0;
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  nacteniPozice(){
    this.comm.getPozice().subscribe(
      (response) => {
        this.typyPozice=response.json();
        console.log(this.typyPozice);
      },
      (error) => console.log(error));
      return this.typyPozice;
  }

  openTyp(j){
    this.j=j;
    this.comm.control_pozice.type=this.typyPozice[j].id;
    console.log(this.typyPozice[j].id);
    this.nacteniCurrent();
  }

  nacteniCurrent(){
    this.comm.postControl(this.comm.control_pozice).subscribe(
      (response) => {
        this.currentData=response.json();
        console.log(this.currentData);
      },
      (error) => console.log(error));
      return this.currentData;
  }

  openZaznam(vybraneid){
    this.nacteniHistorie();
    this.comm.control_id.smenaId=this.currentData[vybraneid].smenaId;
    console.log(this.comm.control_id.smenaId);
    //if (this.currentData[vybraneid].KartaB_timestamp == null){
    //  console.log("NENí KARTA B");
    //  this.zaznam.pn=this.currentData[vybraneid].pn;
    //  this.zaznam.batch=this.currentData[vybraneid].batch;
    //  this.zaznam.osoba=this.comm.getAuthUser().cislo;
    //  this.zaznam.jmeno=this.currentData[vybraneid].jmeno;
    //  this.zaznam.smena_id=this.currentData[vybraneid].smenaId;
    //  this.open();
    //  console.log(this.zaznam);
    //  console.log("DUPLIKACE");
    //}
    //else {
      if (this.currentData[vybraneid].kartaA == 1){
        console.log("ZáZNAM KARTY A")
        this.zaznam.pn=this.currentData[vybraneid].pn;
        this.zaznam.batch=this.currentData[vybraneid].batch;
        this.zaznam.osoba=this.comm.getAuthUser().cislo;
        this.zaznam.jmeno=this.currentData[vybraneid].jmeno;
        this.zaznam.smena_id=this.currentData[vybraneid].smenaId;

        this.comm.postKontrolniRozmery(this.zaznam).subscribe(
          (response) => {
            this.kontrolniRozmery=response.json();
            console.log(this.kontrolniRozmery);
            this.open2();
            this.nacteniHistorie();
            console.log(this.zaznam.smena_id);
         },
          (error) => {console.log(error); this.notifier.notify('danger', 'Chyba')
        });

        ;
      }
      else {console.log("ZDE NENí KONTROLA ROZMěRU");}
   // }
  }

  postKartaB(form:NgForm){
    if (form.value.note!=''){this.zaznam.note=form.value.note};
   
    if (form.value.date!=''){this.zaznam.date=form.value.date};
    if (form.value.time!=''){this.zaznam.time=form.value.time};

    this.zaznam.datum=this.zaznam.date+" "+form.value.time+":00";
    this.zaznam.identifier2="ZBK";
    if (form.value['check_visual']==true){this.zaznam.check_visual=1} else{this.zaznam.check_visual=0}; 
    if (form.value['check_place']==true){this.zaznam.check_place=1} else{this.zaznam.check_place=0};
    if (form.value['check_training']==true){this.zaznam.check_training=1} else{this.zaznam.check_training=0};

    console.log(this.zaznam);
    console.log("DUPLIKACE");

    this.comm.postKartaB(this.zaznam).subscribe(
      (response) => {
        console.log(response);
        this.notifier.notify('success', 'Data odeslána');
        this.nacteniCurrent();
        this.close();
      },
      (error) => {console.log(error); this.notifier.notify('error', 'Chyba, data nebyla odeslána')
    });
  }

  postKartaA(form:NgForm){
    console.log("XXX");
    console.log(form.value.id);
    console.log("YYY");
    if (form.value.id==null || form.value.id==0 ){
      this.notifier.notify('error', 'Vyberte název měření a poté opět potvrďte pro odeslání');
    }
    else{
    if (form.value.rozmer!=''){this.zaznamRozmer.measure_data=form.value.mereni};
    this.zaznamRozmer.id = form.value.id;
    //console.log(form.value);
    this.zaznamRozmer.user_controlled = this.zaznam.osoba;
    this.zaznamRozmer.smena_id = this.zaznam.smena_id;

    this.zaznamRozmer.measure_data=this.zaznamRozmer.measure_data.replace(/,/g, '.');

    //console.log(this.zaznamRozmer);

    this.comm.postKartaA(this.zaznamRozmer).subscribe(
        (response) => {
          console.log(response);
          this.notifier.notify('success', 'Data odeslána');
          form.value.mereni='';
          this.nacteniHistorie();
          form.reset();
          this.nacteniCurrent();
          //this.close();
          },
        (error) => {console.log(error); this.notifier.notify('danger', 'Chyba, data nebyla odeslána')});
      }
  }

  open(){
    this.itemclass = "block";
   }
  
   close(){
     this.itemclass = "none";
     this.itemclass2 = "none";
    }

    open2(){
      this.itemclass2 = "block";
     }

     nacteniHistorie(){
      
      this.comm.postNamereneRozmerySmena(this.zaznam).subscribe(
        (response) => {
          this.historieKartaA=response.json();
          console.log(this.historieKartaA);
        },
        (error) => console.log(error));
        return this.historieKartaA;
    }

}
