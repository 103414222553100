import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';;
import { NotifierModule } from 'angular-notifier';
import * as moment from 'moment';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';

import { CommService } from './comm.service';

import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';

import { PotvrzeniComponent } from './potvrzeni/potvrzeni.component';
import { PotvrzeniZmetkuComponent } from './potvrzeniZmetku/potvrzeniZmetku.component';
import { PotvrzeniSmenyComponent } from './potvrzeniSmeny/potvrzeniSmeny.component';
import { PotvrzeniAuditComponent } from './potvrzeniAudit/potvrzeniAudit.component';

/** Dashboard */
import { DashboardComponent } from './dashboard/dashboard.component';

/** Zmetkovitost */
import { PnComponent } from './zavady/pn/pn.component';
import { AssyComponent } from './zavady/assy/assy.component';

import { KartaAComponent } from './zavady/kartaA/kartaA.component';
import { KartaBComponent } from './zavady/kartaB/kartaB.component';

import { ZmetkovitostStatComponent } from './zavady/stav/stav.component';

import { ZavadyComponent } from './zavady/zavady.component';
import { ZavadyVyberComponent } from './zavady/vyber/vyber.component';
import { ZmetkovitostKComponent } from './zavady/zmetkovitostkomponent/zmetkovitostkomponent.component';
import { ZmetkovitostVComponent } from './zavady/zmetkovitostvyroba/zmetkovitostvyroba.component';
import { ZmetkovitostTComponent } from './zavady/zmetkovitosttester/zmetkovitosttester.component';

/** Audit */
import { AuditComponent } from './audit/audit.component';
import { LsaDenniComponent } from './audit/lsa1/lsa1.component';
import { LsaTydenniComponent } from './audit/lsa7/lsa7.component';
import { LsaTydenniSkladComponent } from './audit/lsa7S/lsa7S.component';
import { LsaMesicniComponent } from './audit/lsa30/lsa30.component';
import { Lsa2MesicneComponent } from './audit/lsa14/lsa14.component';


/** TPM */
import { UdrzbaComponent } from './udrzba/udrzba.component';
import { UdrzbaHistorieComponent } from './udrzba/udrzbaHistorie/udrzbaHistorie.component';

import { UdrzbaVyberComponent } from './udrzba/udrzbaVyber/udrzbaVyber.component';
import { UdrzbaZaznamComponent } from './udrzba/udrzbaZaznam/udrzbaZaznam.component';

/** Kontrola */
import { KontrolaComponent } from './kontrola/kontrola.component';
import { KartaAHistorieComponent } from './kontrola/kartaAHistorie/kartaAHistorie.component';
import { KartaBHistorieComponent } from './kontrola/kartaBHistorie/kartaBHistorie.component';

/** Logistika */
import { LogistikaComponent } from './logistika/logistika.component';

import { DatePipe } from '@angular/common';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'potvrzeni', component: PotvrzeniComponent },
  { path: 'potvrzeni-zmetku', component: PotvrzeniZmetkuComponent },
  { path: 'potvrzeni-smeny', component: PotvrzeniSmenyComponent },
  { path: 'potvrzeni-audit', component: PotvrzeniAuditComponent },

/** Zmetkovitost */
  { path: 'zavady', component: ZavadyComponent },
  { path: 'zavady/vyber', component: ZavadyVyberComponent },

/** Audit */
  { path: 'audit', component: AuditComponent },
  { path: 'lpa1', component: LsaDenniComponent },
  { path: 'lpa7', component: LsaTydenniComponent },
  { path: 'lpa7s', component: LsaTydenniSkladComponent },
  { path: 'lpa30', component: LsaMesicniComponent },
  { path: 'lpa14', component: Lsa2MesicneComponent },

/** TPM */
  { path: 'tpm', component: UdrzbaComponent },
  { path: 'tpm/vyber', component: UdrzbaVyberComponent },
  { path: 'tpm/zaznam', component: UdrzbaZaznamComponent },
  { path: 'tpm/historie', component: UdrzbaHistorieComponent },

/** Kontrola */
  { path: 'control', component: KontrolaComponent },
  { path: 'kartaA', component: KartaAHistorieComponent },
  { path: 'kartaB', component: KartaBHistorieComponent },

/** Logisitka */
  { path: 'logistika', component: LogistikaComponent },

];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PotvrzeniComponent,
    PotvrzeniZmetkuComponent,
    PotvrzeniSmenyComponent,
    PotvrzeniAuditComponent,

/** Dashboard */
    DashboardComponent,

/** Zmetkovitost */
    PnComponent,
    AssyComponent,
    KartaAComponent,
    KartaBComponent,
    ZmetkovitostStatComponent,
    ZavadyComponent,
    ZavadyVyberComponent,
    ZmetkovitostKComponent,
    ZmetkovitostVComponent,
    ZmetkovitostTComponent,

/** Audit */
    AuditComponent,
    LsaDenniComponent,
    LsaTydenniComponent,
    LsaTydenniSkladComponent,
    LsaMesicniComponent,
    Lsa2MesicneComponent,

/** TPM */
    UdrzbaComponent,
    UdrzbaHistorieComponent,
    UdrzbaVyberComponent,
    UdrzbaZaznamComponent,

/** Kontrola */
    KontrolaComponent,
    KartaAHistorieComponent,
    KartaBHistorieComponent,

  
/** Logistika */
    LogistikaComponent,

  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    NotifierModule.withConfig({
      position: {horizontal: {position: 'right'}},
      behaviour: {autoHide: 3000}
    })
  ],
  exports: [RouterModule],
  providers: [CommService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
