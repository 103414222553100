import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";
import { FormControl} from '@angular/forms';
import { Observable} from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.css']
})

export class DashboardComponent implements OnInit{
  today: number = Date.now();
  year= new Date().getFullYear();
  prefix_batch="PK/"+this.year+"/";
  
  smena={id:null, sestavy_id:null, total:null, dateZacatku:Date, dateUkonceni:Date, batch:"", operator: "", pripravek:"", ip:"", smenaId:null, length:null};
  
  seznampn=[];
  pripravky;

  lock=0;
  pn;
  check;
  assy=[];
  myControl = new FormControl();
  myControl2 = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  
  Produkt;
  ProduktID;
  Pripravek;
  Batch;
  
  seconds=5;
  interval = null;

  private notifier: NotifierService;

  itemclass;
  itemclass2;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){this.notifier = notifier}

  ngOnInit() {
    this.check=0;
    this.lock=0;

    this.itemclass = "none";
    this.itemclass2 = "none";
    this.smena_kontrola();

    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.filteredOptions2 = this.myControl2.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter2(value))
      );
  }
  
  getAuthUser() {
    return this.comm.getAuthUser()
  }

  canDisplay(what = '') {
    return this.comm.can('read', what)
  }

  logout() {
    return this.comm.logout()
  }


  // ***get: 

  // ***post: getPosledniSmena(user_no)

  //zjištění aktuální směny, pokud délka proměnné = 0, není žádná aktuální směna
  smena_kontrola(){
      this.comm.getPosledniSmena(this.getAuthUser()).subscribe(
        (response) => {
          this.smena=response.json();
          console.log(this.smena);
          if(this.smena.length > 0) { if (this.smena[0].dateUkonceni === null){document.getElementById("Zmetkovitost").style.boxShadow = "0px 0px 0px 10px var(--red)";}}
        },
        (error) => console.log(error));
        return this.smena;
  }

  //kliknutí na modul "Zmetkovitost"
  zmetkovitost(){
    if(this.smena.length === 0){this.getseznampn(); this.getZarizeni(); this.Batch=this.prefix_batch;}
    else if(this.smena[0].dateUkonceni === null){
                              this.comm.pn.pn=this.smena[0].pn;
                              this.comm.zaznamSmeny.smenaId=this.smena[0].smenaId;
                              this.comm.zaznamSmeny.batch=this.smena[0].batch;
                              this.comm.zaznamSmeny.pripravek=this.smena[0].pripravek;
                              this.comm.zaznamSmeny.pn=this.smena[0].pn;
                              this.comm.zaznamSmeny.osoba=this.comm.getAuthUser().cislo;
                              this.loading();
                            }
    else {
      this.getseznampn();
      this.getZarizeni();
      this.Batch=this.prefix_batch;
    };
  }

  getseznampn(){
    this.comm.getPn().subscribe(
      (response) => {
        this.seznampn=response.json();
        console.log(this.seznampn);
        document.getElementById("polozka").style.borderColor = "#65C728";
        this.open();
      },
      (error) => console.log(error));
      return this.seznampn;
  }

  getZarizeni(){
    this.comm.getZarizeni().subscribe(
      (response) => {
        this.pripravky=response.json();
      },
      (error) => console.log(error));
      return this.pripravky;
  }

  open(){
    this.itemclass = "block";
   }
  
   close(){
    this.itemclass = "none";
    this.check=0;
  }
 
  onSubmit(Produkt, Batch, Pripravek){
    this.check=this.check+1;
    console.log("CHECK + 1");console.log(this.check);
    if (this.check===1){
        this.comm.pn.pn=this.Produkt;
    if (this.comm.pn.pn==""){
      this.check=0;
      alert("Vyber PN."); 
      console.log("^ CHECK + 0"); 
      console.log(this.check)
    }
    else{this.getProductCheck(Produkt)}

    }
    else{
      this.notifier.notify('success', 'Počkejte'); 
    }
  }

  loading(){
    this.itemclass = "none";
    this.itemclass2 = "block";
    this.dataloading();

    this.seconds=5;
    this.interval = setInterval(() => {
      if(this.seconds >=0) {
        this.seconds--;
        if(this.seconds===0){
          this.router.navigate(['/zavady']);
          this.check=1;
        }
      } else {
                clearInterval(this.interval);
              }
    }, 1000);
  }

  dataloading(){
    this.getProduct();
  }

  getProductCheck(Produkt){
    this.comm.getpnvyber(this.comm.pn).subscribe(
      (response) => {
        this.pn=response.json();
        console.log("Doublecheck");
        this.ProduktID=this.pn[0].id;
        console.log(this.ProduktID);
        if (this.pn.length==0){
          this.close(); 
          this.Produkt=null; 
          this.notifier.notify('error', 'Špatné PN, vyberte ještě jednou!');
        }
      
        else{this.schicht_new();
          this.notifier.notify('success', 'Počkejte na načtení směny');
      }
      },
      (error) => console.log(error));
      return this.pn;
  }

  getProduct(){
    this.comm.getpnvyber(this.comm.pn).subscribe(
      (response) => {
        this.pn=response.json();
        this.comm.ps_pn=this.pn[0];
        console.log(this.pn);
        this.getKomponenty();
        this.getZavady_komponent();
        this.getZavady_vyroba();
        this.getZavady_tester();
      },
      (error) => console.log(error));
      return this.pn;
  }

  getKomponenty(){
    this.comm.getKomponenty(this.comm.ps_pn).subscribe(
      (response) => {
        this.comm.ps_assy=response.json();
        console.log(this.comm.ps_assy);
      },
      (error) => console.log(error));
      return this.assy;
  }

  getZavady_komponent(){
    this.comm.getZavadyK(this.comm.ps_pn).subscribe(
      (response) => {
        this.comm.zavadyk=response.json();
        console.log(this.comm.zavadyk);
      },
      (error) => console.log(error));
      return this.comm.zavadyk;
  }

  getZavady_vyroba(){
    this.comm.getZavadyV(this.comm.ps_pn).subscribe(
      (response) => {
        this.comm.zavadyv=response.json();
        console.log(this.comm.zavadyv);
      },
      (error) => console.log(error));
      return this.comm.zavadyv;
  }

  getZavady_tester(){
    this.comm.getZavadyT(this.comm.ps_pn).subscribe(
      (response) => {
        this.comm.zavadyt=response.json();
        console.log(this.comm.zavadyt);
      },
      (error) => console.log(error));
      return this.comm.zavadyt;
  }



  schicht_new(){
    this.comm.getNovaSmenaId().subscribe(
      (response) => {
        this.smena=response.json();
        this.comm.zaznamSmeny.smenaId=this.smena[0].id+1;

        this.comm.zaznamSmeny.batch=this.Batch;
        this.comm.zaznamSmeny.pripravek=this.Pripravek;
        this.comm.zaznamSmeny.pn=this.Produkt;
        this.comm.zaznamSmeny.sestavy_id=this.ProduktID;
        this.comm.zaznamSmeny.osoba=this.comm.getAuthUser().cislo;
        this.comm.zaznamSmeny.ip=this.comm.ipAddress;
        console.log(this.comm.zaznamSmeny);
        this.comm.postNovyZaznamSmena(this.comm.zaznamSmeny).subscribe(
               (response) => {
               console.log(response);
               this.loading();
                },
                (error) => console.log(error));
              },
              (error) => console.log(error));
        
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.seznampn.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filter2(value: string): string[] {
    const filterValue2 = value.toLowerCase();

    return this.pripravky.filter(option => option.toLowerCase().includes(filterValue2));
  }

}
