import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";



import { Router } from '@angular/router';

@Component({
  selector: 'app-udrzbaVyber',
  templateUrl: './udrzbaVyber.component.html',
  styleUrls: ['../../app.component.css']
})
export class UdrzbaVyberComponent implements OnInit{


  today: number = Date.now();
  seznamZarizeni=[];
  typyUdrzba=[];
  zaznamUdrzbyPost={"typUdrzby_id":"", "zarizeni_id":"", "notes":"", "user_start":"", "user_finish":"", "reference_notFinished":"", "timestamp_finish":"", "ip":"" };
  responseInsert={"insertId":""};

  constructor(private comm: CommService,
    private router:Router){}

  ngOnInit() {
    this.typyUdrzba=this.comm.data_typyUdrzby;
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.nacteniZarizeni();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  openTyp(j){
    this.comm.typyUdrzby.id=this.typyUdrzba[j].id;
    this.nacteniZarizeni();
    this.router.navigate(['/tpm/vyber']);
    console.log(this.typyUdrzba[j].id);
  }

  nacteniZarizeni(){
    delete this.seznamZarizeni;
    this.comm.postZarizeni(this.comm.typyUdrzby).subscribe(
      (response) => {
        this.seznamZarizeni=response.json();
        console.log(this.seznamZarizeni);
        this.comm.seznamZarizeni=this.seznamZarizeni;
        this.changeColor();
      },
      (error) => console.log(error));
      return this.seznamZarizeni;
  }



  vypisZarizeni(vybraneid){
    this.comm.id=this.seznamZarizeni[vybraneid].id;
    if (this.seznamZarizeni[vybraneid].reference_notFinished>0){
        console.log("Nedokončená údržba");
        this.comm.zaznamUdrzby.id=this.seznamZarizeni[vybraneid].udrzba_id;
        this.router.navigate(['/tpm/zaznam']);
    }
   else{
        console.log("Všechny předchozí údržby dokončené");
        this.zaznamUdrzbyPost.zarizeni_id=this.seznamZarizeni[vybraneid].id;
        this.zaznamUdrzbyPost.typUdrzby_id="1";
        this.novyZaznamUdrzba();
    }
  }

  changeColor(){
    var i;
    for (i = 0; i < this.seznamZarizeni.length; i++) {
    if (Date.parse(this.seznamZarizeni[i].datePlan) <= Date.now()){this.seznamZarizeni[i].status="bg-red"}
    else {this.seznamZarizeni[i].status="bg-grey"}
  }
}



  udrzbaZobraz(){
    var x = document.getElementById("udrzba"+this.comm.id);
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
  }

  button1(vybraneid){
    this.zaznamUdrzbyPost.zarizeni_id=this.seznamZarizeni[vybraneid].id;
    this.zaznamUdrzbyPost.typUdrzby_id="1";
    this.novyZaznamUdrzba();
  }

  button2(vybraneid){
    this.zaznamUdrzbyPost.zarizeni_id=this.seznamZarizeni[vybraneid].id;
    this.zaznamUdrzbyPost.typUdrzby_id="2";
    this.novyZaznamUdrzba();
  }


  novyZaznamUdrzba(){
    this.zaznamUdrzbyPost.user_start=this.comm.getAuthUser().cislo;
    this.zaznamUdrzbyPost.user_finish=null;
    this.zaznamUdrzbyPost.timestamp_finish=null;
    this.zaznamUdrzbyPost.notes=null;
    this.zaznamUdrzbyPost.reference_notFinished="1";
    this.zaznamUdrzbyPost.ip=this.comm.ipAddress;
    
    this.comm.insertUdrzba(this.zaznamUdrzbyPost).subscribe(
      (response) => {
        this.responseInsert=response.json();
        this.comm.zaznamUdrzby.id=this.responseInsert.insertId;
        this.router.navigate(['/tpm/zaznam'])
      },
      (error) => console.log(error));
  }

}
