import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-logistika',
  templateUrl: './logistika.component.html',
  styleUrls: ['../app.component.css']
})
export class LogistikaComponent implements OnInit{

  today: number = Date.now();
  chybiKomponenta=[];
  itemclass3;
  komponenta={"image":"", "pn":""};

  private notifier: NotifierService;

  constructor(private comm: CommService, private router:Router, notifier: NotifierService){  this.notifier = notifier;}

  ngOnInit() {
    this.today = setInterval( () => {
           this.today = Date.now();
    }, 1000);
    this.infinite();
    this.itemclass3 = "none";
    this.nacteniChybi();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  infinite(){
   setInterval( () => {this.nacteniChybi();}, 10000);
  }

  nacteniChybi(){
    this.comm.getChybiKomponenta().subscribe(
      (response) => {
        this.chybiKomponenta=response.json();
        console.log(this.chybiKomponenta);
      },
      (error) => console.log(error));
      return this.chybiKomponenta;
  }

  selectChybi(vybraneid){
  this.comm.chybiKomponenta.id=this.chybiKomponenta[vybraneid].id;
  this.komponenta.image=this.chybiKomponenta[vybraneid].image;
  this.komponenta.pn=this.chybiKomponenta[vybraneid].pn;
  this.open();
  }

  open(){
    this.itemclass3 = "block";
  }

  close(){
    this.itemclass3 = "none";
 }

  
  onSubmitChybi(){
    this.comm.chybiKomponenta.status=0;
    this.comm.chybiKomponenta.timestamp_finish=moment().format('YYYY-MM-DD HH:mm:ss');
    this.comm.chybiKomponenta.user_finish=this.comm.getAuthUser().cislo;
    console.log(this.comm.chybiKomponenta);
    this.comm.updateChybiKomponenta(this.comm.chybiKomponenta).subscribe(
      (response) => {
      console.log(response);
      this.itemclass3 = "none";
      this.notifier.notify('success', 'Materiál dodán');
      this.nacteniChybi();
      this.router.navigate(['/logistika']);
      
       },
      (error) => console.log(error));
  }

}
